require("@rails/ujs")

SELECTOR = "[data-track='impression']"

init = ->
  for impressable in document.querySelectorAll(SELECTOR)
    $impressable = $(impressable)
    unless $impressable.data("impressed") == true
      impressableType = $impressable.data("impressable")
      slug = $impressable.data("slug")

      Rails.ajax
        url: "/api/#{impressableType}s/#{slug}/impressions.json"
        type: "post"
        success: ->
          $impressable.data("impressed", true)
          console.debug("Impressions registered")

export { init }

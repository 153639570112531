require("@rails/ujs")

SELECTOR = "a[data-track='click']"

init = ->
  $("body").on "click", SELECTOR, onClick

onClick = (e) ->
  $clickable = $(this)
  unless $clickable.data("clicked") == true
    clickableType = $(this).data("clickable")
    slug = $(this).data("slug")
    Rails.ajax
      url: "/api/#{clickableType}s/#{slug}/clicks.json"
      type: "POST"
      success: ->
        $clickable.data("clicked", true)
        console.debug("Click registered")

export { init }
